
import googleAnalyticsEvents from "~/mixins/googleAnalyticsEvents";
export default {
  name: "SubscriptionBlock",
  mixins: [googleAnalyticsEvents],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    blockData: {
      type: Array,
      default: () => [],
    },
    pageData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    canSendGoogleAnalyticsStatistic() {
      return (
        this.integrationCallbackStatuses.TYPE_GOOGLE_ANALYTICS &&
        this.blockData?.length
      );
    },
    statisticItemListName() {
      return this.pageData?.mixedTranslation?.title || this.data?.title;
    },
  },
  watch: {
    canSendGoogleAnalyticsStatistic: {
      handler() {
        this.gaViewItemListEvent(this.blockData, {
          item_list_name: this.statisticItemListName,
        });
      },
      immediate: true,
    },
  },
};
